import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import StarIcon from '@mui/icons-material/Star';

const CardExpanded = ({ product, onClick, currencies }) => {
  const { image, name, rating, price } = product;

  return (
    <div>
      <Paper
        elevation={0} // Pas d'élévation pour le papier parent
        style={{
          width: '100%',
          marginTop: '8px',
          marginBottom: '8px',
          borderRadius: '16px',
          position: 'relative',
          backgroundImage: `url(${image})`, // Image de fond
          backgroundSize: 'cover', // Redimensionner l'image pour couvrir tout le papier
          backgroundPosition: 'center', // Centrer l'image sur le papier
          backgroundRepeat: 'no-repeat', // Ne pas répéter l'image
          opacity: 0.8, // Opacité pour l'effet de verre
          backdropFilter: 'blur(10px)', // Flou pour l'effet de verre
        }}
      >
        <Card
          onClick={() => onClick(product)}
          style={{
            borderRadius: '16px',
            background: 'transparent', // Fond transparent pour la carte
            backdropFilter: 'blur(25px)', // Flou pour l'effet de verre
          }}
        >
        <CardMedia
            component="img"
            height="100"
            image={image}
            alt={name}
            style={{ objectFit: 'cover' }}
          />
          <CardContent
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              backgroundColor : 'rgba(192, 192, 192, 0.8)',backdropFilter: 'blur(10px)',boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)'
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h7"
                sx={{
                  fontWeight: 800,
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#161b22',
                }}
              >
                {name}
                <div
              style={{
                top: '8px',
                right: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h3
                style={{
                  margin: 0,
                  marginRight: '4px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {rating}
              </h3>
              <StarIcon style={{ color: 'yellow' }} />
            </div>
              </Typography>
              
              <Typography
                variant="h7"
                sx={{ alignSelf: 'flex-end', color: '#161b22' }}
              >
                {`${price.toFixed(2)} ${currencies?.code}`}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
};

export default CardExpanded;
