import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AllComponents from './All-components';
import HomePage from './HomePage/HomePage';
function App() {
  

  return (
    <Router>
      <Routes>
        <Route path="/restaurant/:id/:numberTable" element={<HomePage/>} />
      </Routes>
    </Router>
  );
}

export default App;
