import React, { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import CardDrink from './CardDrink/CardDrink';
import CardExpanded from './Card/Card';
import ProductCard from './Card/ProductCard';
import { connect } from 'react-redux';
import { addToCart } from "./actions";
import { v4 as uuidv4 } from 'uuid';

const Item = ({ data = {}, onAddToCart, currencies }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleClick = (product) => {
    setSelectedProduct(product);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  const handleAddToCart = (product) => {
    const cartItem = {
      id: uuidv4(),
      productId: product.id,
      image: product.image,
      name: product.name,
      price: product.price,
      quantity: 1,
    };
    onAddToCart(cartItem);
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const visiblePercentage = 1; // Percentage of scroll to start hiding components
  const opacity = 1 - (scrollPosition * 0.01 * visiblePercentage);

  return (
    <div style={{ marginTop: '5%' }}>
      <section className='Menus' id={data.id} style={{ display: 'flex', flexDirection: data.position === 'horizontal' ? 'row' : 'column' }}>
        {data.data && data.data.length > 0 && data.data.map(cat => (
          <div key={cat.id} style={{ width: data.position === 'horizontal' ? '50%' : '100%',marginLeft: '9px', }}>
            <h3 style={{color:'rgba(192, 192, 192, 0.5)'}}>{cat.nom}</h3>
            {cat.dataProduct && cat.dataProduct.length > 0 && (
              <div className="card-container" style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                {cat.dataProduct.map((product, index) => (
                  <div key={index} style={{ flex: '0 0 50%', maxWidth: '50%',margin: '0 5px'  }}>
                    <CardExpanded key={index} product={product} onClick={() => handleClick(product)} currencies={currencies} />
                    {/*(product.uniqueSelected && product.uniqueSelected.length > 0) || (product?.allergenes && product?.allergenes.length > 0) ? (
                      <CardExpanded key={index} product={product} onClick={() => handleClick(product)} currencies={currencies} />
                    ) : (
                      <CardDrink key={index} product={product} onClick={() => handleAddToCart(product)} currencies={currencies} />
                    )*/}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </section>

      <Modal open={openModal} onClose={handleClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'auto', color: 'rgba(0,0,0,0.1)', overflow: 'hidden' }}>
        <div style={{ backgroundColor:'#161b22', flex: 1, borderRadius: '8px', maxWidth: '80%', width: '40%', boxSizing: 'border-box', paddingRight: '20%' }}>
          <ProductCard product={selectedProduct} onClose={handleClose} currencies={currencies} />
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onAddToCart: (cartItem) => dispatch(addToCart(cartItem)),
});

export default connect(null, mapDispatchToProps)(Item);
