import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';

const ArticleCard = ({ article, onIncrement, onDecrement, onDelete, currencies }) => {
  const {id, name, note, price, image, quantity, supplements, multiSelects, uniqueSelects } = article;
  console.log("test", uniqueSelects)
  return (
    <Card style={{ backgroundColor: '#161b22',}}>
      <CardContent style={{ display: 'flex', alignItems: 'center', }}>
        <div style={{ marginRight: '16px' }}>
          <Avatar
            src={image}
            sx={{
              width: 40,
              height: 40,
              background: '#f50057',
            }}
          />
        </div>
        <div style={{display:'flex',flexDirection:'column' }}>
          <Typography variant="h7" style={{color:'rgba(192, 192, 192, 0.5)'}}>{name} x{quantity}</Typography>
          <Typography variant="h7" style={{color:'rgba(192, 192, 192, 0.5)'}}>{price} <span style={{fontSize:12}}>{currencies?.code}</span></Typography>
        </div>
        <DeleteIcon onClick={() => onDelete(article)} style={{ cursor: 'pointer', marginLeft: 'auto', color:'rgba(192, 192, 192, 0.5)' }} />
      </CardContent>

      {supplements && supplements.length > 0 && (
        <div>
          <ul>
            {supplements.map((supplement, index) => (
              <li style={{color:'rgba(192, 192, 192, 0.5)'}} key={index}>{`${supplement.name} x${supplement.quantity} (${supplement.price} ${currencies?.code})`}</li>
            ))}
          </ul>
        </div>
      )}

      {multiSelects && multiSelects.length > 0 && (
        <div>
          <ul>
            {multiSelects.map((multiSelect, index) => (
              <li style={{color:'rgba(192, 192, 192, 0.5)'}} key={index}>{`${multiSelect.name} (${multiSelect.price} ${currencies?.code})`}</li>
            ))}
          </ul>
        </div>
      )}

      {uniqueSelects && Object.keys(uniqueSelects).length > 0 && (
  <div>
    <ul>
      {Object.entries(uniqueSelects).map(([nameCat, uniqueSelects], index) => (
        <li style={{color:'rgba(192, 192, 192, 0.5)'}} key={index}>{`${uniqueSelects.name} (${uniqueSelects.price} ${currencies?.code})`}</li>
      ))}
    </ul>
  </div>
)}

      {note && note.length > 0 && <h4 variant="h7" style={{color:'rgba(192, 192, 192, 0.5)'}}>Note: {note}</h4>}
    </Card>
  );
};

export default ArticleCard;
