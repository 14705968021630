// store.js
import { createStore } from 'redux';

const cartReducer = (state = [], action) => {
  console.log("reducer ", action.payload)
  switch (action.type) {
    case 'ADD_TO_CART':
      return [...state, action.payload];
    case 'INCREMENT_QUANTITY':
      // Implement logic to increase quantity
      return state;
    case 'DECREMENT_QUANTITY':
      // Implement logic to decrease quantity
      return state;
    case 'REMOVE_FROM_CART':
      // Implement logic to remove from cart
      return state.filter(item => item.id !== action.payload.id);
    case 'CLEAR_CART':
        // Clear the entire cart
      return [];      
    default:
      return state;
  }
};

const store = createStore(cartReducer);

export default store;
