// StarRating.js
import React from 'react';
import './StarRating.css';

const StarRating = ({ rating }) => {
  const roundedRating = Math.round(rating * 2) / 2; // Round to the nearest 0.5
  console.log("rating", rating)
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      let starClass = 'star';
      
      // Determine color based on the rating
      if (i <= roundedRating) {
        starClass += ' filled';
      } else if (i === Math.ceil(roundedRating) && roundedRating % 1 !== 0) {
        starClass += ' half-filled';
      }

      stars.push(<div key={i} className={starClass}></div>);
    }
    return stars;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;
