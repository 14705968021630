// actions.js

// Action Types
export const ADD_TO_CART = 'ADD_TO_CART';
export const INCREMENT_QUANTITY = 'INCREMENT_QUANTITY';
export const DECREMENT_QUANTITY = 'DECREMENT_QUANTITY';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SHOW_FROM_CART = 'SHOW_FROM_CART';


// Action Creators
export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
});

export const incrementQuantity = (item) => ({
  type: INCREMENT_QUANTITY,
  payload: item,
});

export const decrementQuantity = (item) => ({
  type: DECREMENT_QUANTITY,
  payload: item,
});

export const removeFromCart = (item) => ({
  type: REMOVE_FROM_CART,
  payload: item,
});
export const ShowFromCart = () => ({
  type: SHOW_FROM_CART,
});
// actions.js
export const clearCart = () => ({
    type: 'CLEAR_CART',
  });
  
