import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';
import { addToCart } from "../actions";
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '../CircularProgress';
import { motion, useMotionValue, useTransform } from "framer-motion"
import Modal from '@mui/material/Modal';
import './ProductCard.css';
import StarRating from '../StarRating';

const getSelectedItems = (selections, categories) => {
  const selectedItems = [];

  // Parcourir chaque catégorie sélectionnée
  for (const categoryName in selections) {
    // Trouver la catégorie correspondante dans les données
    const category = categories.find(cat => cat.nameCat === categoryName);
    // Si la catégorie est trouvée, ajouter l'élément sélectionné au tableau
    if (category) {
      const selectedItem = category.data.find(item => item.name === selections[categoryName]);

      if (selectedItem) {
        selectedItems.push({
          nameCat:categoryName,
          name: selectedItem.name,
          price: selectedItem.price,
        });
      }
    }
  }
  return selectedItems;
};
const calculateTotal = (data) => {
  return data.reduce((total, supplement) => {
    return total + supplement.price * (supplement?.quantity ?? 1);
  }, 0);
};

const ProductCard = ({ product, onAddToCart, onClose, currencies}) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  //const [selectedAllRadio, setSelectedAllRadio] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [selections, setSelections] = useState({});
  const [note, setNote] = useState('');
  const [message, setMessage] = useState('')
  let progress = useMotionValue(90)
  const [isScrolled, setIsScrolled] = useState(false);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };


  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const handleSelection = (categoryName, selectedItem) => {
    setSelections(prevSelections => ({
      ...prevSelections,
      [categoryName]: selectedItem,
    }));
  };

  

  const handleCheckboxChange = (supplement) => {
    const existingCheckboxesIndex = selectedCheckboxes.findIndex(s => s._id === supplement._id);
  
    if (existingCheckboxesIndex !== -1) {
      setSelectedCheckboxes(selections =>
        selections.filter(selection => selection._id !== supplement._id)
      );
    } else {
      // If not found, add it with quantity 1
      setSelectedCheckboxes(prev => [...prev, { ...supplement, quantity: 1 }]);
    }
  };
  
  const handleQuantityChange = (supplementId, action) => {
    setSelectedSupplements(prevSupplements =>
      prevSupplements.map(supplement =>
        supplement._id === supplementId ? {
          ...supplement,
          quantity: action === 'increase' ? supplement.quantity + 1 :
                    supplement.quantity > 1 ? supplement.quantity - 1 : 1 // Prevent quantity from going below 1
        } : supplement
      )
    );
  };
  

 

  const handleAddSupplement = (supplement) => {
    const existingSupplementIndex = selectedSupplements.findIndex(s => s._id === supplement._id);
  
    if (existingSupplementIndex !== -1) {
      // If found, increase its quantity
      const updatedSupplements = [...selectedSupplements];
      updatedSupplements[existingSupplementIndex].quantity += 1;
      setSelectedSupplements(updatedSupplements);
    } else {
      // If not found, add it with quantity 1
      setSelectedSupplements(prev => [...prev, { ...supplement, quantity: 1 }]);
    }
    console.log("select : ",selectedSupplements)
  };
  

  const handleRemoveSupplement = (supplementId) => {
    setSelectedSupplements(prevSupplements =>
      prevSupplements.filter(supplement => supplement._id !== supplementId)
    );
  };
  


  
  const handleAddToCart = () => {
    
    const uniqueSelects = getSelectedItems(selections, product?.uniqueSelected);

const checkRequiredSelections = (selections, selectedItems) => {
  let allRequiredSelected = true; // Variable pour suivre l'état de la vérification
  for (const selection of selections) {
    const isNameCatPresent = selectedItems.some(item => item.nameCat === selection.nameCat);
    if (!isNameCatPresent) {
      const isNameProdPresent = selectedItems.flatMap(item => item.data?.map(prod => prod.name)).includes(selection.data.name);
      console.log("selections, selectedItems.....",isNameProdPresent, selectedItems)

      if(!isNameProdPresent){
          setMessage(`${selection.nameCat}`);
        setTimeout(() => {
          setMessage('');
        }, 5000);
        allRequiredSelected = false; // Mettre la variable à false si une sélection requise est manquante
      }
      
    }
  }
  return allRequiredSelected; // Retourner l'état final de la vérification
};

if (!checkRequiredSelections(product.uniqueSelected.filter(selection => selection.required), uniqueSelects) ||
    !checkRequiredSelections(product.multipleSelected.filter(selection => selection.required), selectedCheckboxes)) {
  return;
}



    setIsSuccessModalOpen(true);
    setTimeout(() => {
      onClose()
      setIsSuccessModalOpen(false);
    }, 2000);

    const productPrice = parseFloat(product.price);
  
    // Ensure that the price of each supplement is a valid number
    
  
    // Calculate the total price including quantity, product price, and supplements
    const priceTotal = ((productPrice+calculateTotal(selectedCheckboxes)+calculateTotal(selectedSupplements)+calculateTotal(uniqueSelects))*quantity).toFixed(2);
  console.log("product ", calculateTotal(selectedCheckboxes), calculateTotal(selectedSupplements), calculateTotal(uniqueSelects), productPrice)
    const cartItem = {
      id:uuidv4(),
      productId: product.id,
      image: product.image,
      name: product.name,
      quantity: quantity,
      price: priceTotal,
      note: note,
      multiSelects: selectedCheckboxes,
      supplements: selectedSupplements,
      uniqueSelects: uniqueSelects,
      // ... (add any other relevant product information)
    };
    console.log('CartItem ', cartItem)
    onAddToCart(cartItem);
  };
  useEffect(()=>{
    setIsScrolled(true);
    setTimeout(() => {
      setIsScrolled(false);
    }, 1500);
  },[])
  console.log("product all......",product?.allergenes )
  return (
    <>
      {message && message.length > 0 &&
        <Card elevation={3} style={{ backgroundColor:'#161b22', marginBottom:'5%', width:'200%',justifyContent:'center', alignItems:'center' }}>
        <CardContent style={{ backgroundColor:'#161b22', width: '50%', textAlign:'center' }}>
        <Typography variant="body1" color="textPrimary">
        Il vous manque un truc 🧐 ?
          </Typography> 
          <Typography variant="body1" color="textPrimary">
            {message}
          </Typography>
        </CardContent>
      </Card>

      }
      <Card className="glass-card" sx={{ height: '100%',backgroundColor:'#161b22',}}>
      <div className="scrollable-container">
        <CardMedia
          component="img"
          alt={product.name}
          width="100%"
          height="60%"
          image={product.image}
          style={{ objectFit: 'cover' }}
        />
        <CardContent className="card-content">
          <Typography variant="h4" sx={{color:'rgba(192, 192, 192, 0.5)'}}>{product.name}</Typography>
          {product.uniqueSelected && product.uniqueSelected.length ||product.multipleSelected && product.multipleSelected.length > 0 ?
           <Typography variant="subtitle1" sx={{color:'rgba(192, 192, 192, 0.5)',margin:'auto' }}> {product.rating.toFixed(2)} <StarRating rating={product.rating.toFixed(2)}/> </Typography>
           :<Typography variant="subtitle1" sx={{color:'rgba(192, 192, 192, 0.5)',margin:'auto' }}>  <StarRating rating={5}/> </Typography>
}
          <Typography variant="body2" sx={{color:'rgba(192, 192, 192, 0.5)', margin:'auto'}}>{product.description}</Typography>
          <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 1)', marginTop:'5px', marginBottom:'5px', }}>{product.price} <span style={{fontSize:14}}>{currencies?.code}</span> </Typography>
          {product?.allergenes &&product?.allergenes.length >0 &&(
            <>
              <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 0.5)'}}>Allergènes:</Typography>
              <div className="allergens-container" style={{color:'rgba(192, 192, 192, 0.5)'}} >
                {product?.allergenes.map((allergen, index) => (
                  <div key={index} className="allergen" style={{color:'rgba(192, 192, 192, 0.5)',}}>
                    {allergen}
                  </div>
                ))}
              </div>
            </>
          )}

          {product.uniqueSelected && product.uniqueSelected.length > 0 && (
  <div style={{marginBottom:'5%'}}>
    {product.uniqueSelected.map(category => (
      <div key={category._id}>
      {category?.required ? (
          <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 0.5)'}}>{category.nameCat} (Choix obligatoire):</Typography>
        ) : (
          <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 0.5)'}}>{category.nameCat}:</Typography>
        )}
        <RadioGroup
          aria-label={category.nameCat}
          name={category.nameCat}
          value={selections[category.nameCat] || ''}
          onChange={(e) => handleSelection(category.nameCat, e.target.value)}
        >
          {category.data.map(option => (
            <div key={option._id}>
              <input
                type="radio"
                id={`${category.nameCat}-${option.name}`}
                name={category.nameCat}
                value={option.name}
                checked={selections[category.nameCat] === option.name}
                onChange={(e) => handleSelection(category.nameCat, e.target.value)}
              />
              <label style={{color:'rgba(192, 192, 192, 1)', }} htmlFor={`${category.nameCat}-${option.name}`}>
                 {`${option.name} - ${option.price} ${currencies?.code}`}
              </label>
            </div>
          ))}
          {console.log("unique ", category.data)}
        </RadioGroup>
      </div>
    ))}
  </div>
)}


{product?.multipleSelected && product?.multipleSelected?.length > 0 && (
  <div style={{marginBottom:'5%'}}>
    {product.multipleSelected.map((selection) => (
      <div key={selection.nameCat}>
        {selection?.required ? (
          <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 0.5)'}}>{selection.nameCat} (Choix obligatoire):</Typography>
        ) : (
          <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 0.5)'}}>{selection.nameCat}:</Typography>
        )}
        {selection.data.map((dataItem, dataIndex) => (
          <div key={dataItem._id}>
            <input
              type="checkbox"
              id={`${selection.nameCat}-${dataItem._id}`}
              onChange={() => handleCheckboxChange(dataItem)}
              checked={selectedCheckboxes.some((s) => s._id === dataItem._id)}
            />
            <label style={{color:'rgba(192, 192, 192, 1)'}} htmlFor={`${selection.nameCat}-${dataItem._id}`}>
              {`${dataItem.name} ${dataItem?.price > 0 ? dataItem.price : ''} ${dataItem?.price > 0 ?currencies?.code:''}`}
            </label>
          </div>
        ))}
      </div>
    ))}
  </div>
)}


{product.supplement && product.supplement.length > 0 && (
  <div style={{marginBottom:'5%'}}>
    {product.supplement.map((category) => (
      <div style={{color:'rgba(192, 192, 192, 0.5)', fontSize:16}} key={category.nameCat}>
        <h3>{category.nameCat}</h3>
        {category.data.map((supplement, index) => (
          <div key={supplement._id}>
            <div>
              <input
                type="checkbox"
                id={`${category.nameCat}-${supplement._id}`}
                checked={selectedSupplements.some((s) => s._id === supplement._id)}
                onChange={() => handleAddSupplement(supplement)}
              />
              <label style={{color:'rgba(192, 192, 192, 1)'}} htmlFor={`${category.nameCat}-${supplement._id}`}>
                {`${supplement.name} - ${supplement.price} ${currencies?.code}`}
              </label>
            </div>
            {selectedSupplements.some((s) => s._id === supplement._id) && (
              <div style={{marginBottom:'10px', marginTop:'5px'}} >
                <button onClick={() => handleQuantityChange(supplement._id, 'decrease')}>-</button>
                <span style={{padding:'2%', fontSize:'100%'}}>{selectedSupplements.find((s) => s._id === supplement._id)?.quantity}</span>
                <button onClick={() => handleQuantityChange(supplement._id, 'increase')}>+</button>
                <button style={{marginLeft:'5%',}} onClick={() => handleRemoveSupplement(supplement._id)}>Annuler</button>
              </div>
            )}
          </div>
        ))}
      </div>
    ))}
  </div>
)}


          <TextField
          id="filled-multiline-static"
          label="Laissez une note"
          multiline
          rows={4}
          variant="filled"
          onChange={handleNoteChange}
          sx={{width:"100%", backgroundColor:'rgba(192, 192, 192, 0.3)'}}
        />
          {/* Section de notes du client ici */}

          <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 0.3)'}} >Quantité:</Typography>
          <div className="quantity-container">
            <IconButton onClick={handleDecrement}>
              <RemoveIcon />
            </IconButton>
            <Typography variant="h6" sx={{color:'rgba(192, 192, 192, 1)'}}>{quantity}</Typography>
            <IconButton onClick={handleIncrement}>
              <AddIcon />
            </IconButton>
          </div>

          <Button variant="contained" onClick={handleAddToCart} sx={{backgroundColor:"black", ":hover":{backgroundColor:"black"}}} fullWidth>
            Ajouter à ma commande
          </Button>

          {product.review?product.review.length > 0 && (
            <>
              <Typography variant="h6">Avis:</Typography>
              {product.review.map((review, index) => (
                <div key={index} className="review">
                  <Typography variant="subtitle1">{review.user}</Typography>
                  <Typography variant="body2">{review.date}</Typography>
                  <Typography variant="subtitle1">{review.rating} étoiles</Typography>
                  <Typography variant="body2">{review.review}</Typography>
                </div>
              ))}
            </>
          ):null}
        </CardContent>
      </div>
    </Card>
    {/* Modal de succès */}
    <Modal open={isSuccessModalOpen}>
  <div style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center', // Ajout de cette ligne pour centrer le contenu
  }}>
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: 100 }}
      style={{ x: progress, width: '50%', margin: '0 auto' }} // Ajustez la taille de l'animation
      transition={{ duration: 1 }}
    />
    <CircularProgress progress={progress} />
  </div>
</Modal>
    </>
    
  );
};
const mapDispatchToProps = (dispatch) => ({
  onAddToCart: (cartItem) => dispatch(addToCart(cartItem)),
});

export default connect(null, mapDispatchToProps)(ProductCard);