import React, { useState } from 'react';
import StarRating from './StarRating';

const RestaurantProfile = ({ logo, restaurantName, stars, socialLinks=[], description='', photos=[], reviews=[]}) => {

    return (
        <div style={{ textAlign: 'center', height:'80vh', width:'85vw', overflow: 'hidden', overflowY: 'auto', paddingRight: '17px', backgroundColor:'#161b22' }}>
            {/* Logo */}
            {logo && logo.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <img src={logo} alt="Logo" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                </div>
            )}
        
            {/* Nom du restaurant et nombre d'étoiles */}
            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h2 style={{color:'rgba(192, 192, 192, 1)'}}>{restaurantName}</h2>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ marginRight: '10px',color:'rgba(192, 192, 192, 0.5)' }}>{stars.toFixed(2)}</div>
                    <StarRating rating={stars} />
                </div>
            </div>
        
            {/* Boutons sociaux */}
            <div style={{ marginTop: '10px' }}>
                {socialLinks.map((link, index) => (
                    <a key={index}  href={link.url.startsWith('http') ? link : `https://${link.url}`} target="_blank" rel="noopener noreferrer">
                        <img src={link.icon} alt="Logo" style={{width:'25px' ,margin:'5px'}} />
                    </a>
                ))}
            </div>
        
            {/* Description */}
            <div style={{ marginTop: '20px', marginLeft:'10px' }}>
                <p style={{color:'rgba(192, 192, 192, 1)'}}>{description}</p>
            </div>
        
            {/* Photos */}
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {photos.map((photo, index) => (
                    <img key={index} src={photo} alt={`Photo ${index}`} style={{ width: '120px', height: '120px', margin: '10px' }} />
                ))}
            </div>
        
            {/* Avis */}
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {reviews.map((review, index) => (
                    <div key={index} style={{ width: '33%', margin: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.7)', borderRadius: '10px', padding: '20px', textAlign: 'left' }}>
                        <p style={{color:'rgba(192, 192, 192, 0.5)'}}><strong>{review.name}</strong></p>
                        <div>
                            <StarRating rating={review.rating}/>              
                        </div>                
                        <p style={{color:'rgba(192, 192, 192, 1)'}}>{review.review}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RestaurantProfile;
