import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Dropdown } from 'rsuite';

const CardNavBar = ({ open, onClose, navItems, onSelectSection, data }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelectSection = (item) => {
    setSelectedItem(item);
    onSelectSection(item);
    onClose(); // Fermer la boîte de dialogue après la sélection de l'image
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{
      position: 'absolute',
      marginBottom: '100%',
      marginLeft: '30%',
      borderRadius: '10px',
    }}>
      <Card
        sx={{
          backgroundColor: 'black',
          opacity: 0.8, // Opacité pour l'effet de verre
          borderRadius: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Effet d'élévation
        }}
      >
        <CardContent sx={{
        backgroundColor: 'black',
          padding: '20px',
          borderRadius: '20px',
        }}>
          <List sx={{ width: '100%', overflowY: 'auto' }}>
            {navItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  onClick={() => handleSelectSection(item.type)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '10px', // Espace entre chaque élément
                    borderBottom: '1px solid #ccc', // Ligne de séparation
                    '&:hover': { boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)' }, // Effet de survol avec élévation
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={item.image} alt={item.type} style={{ marginRight: '10px', width: '32px', height: '32px' }} />
                    <ListItemText primary={item.type} sx={{color:'rgba(192, 192, 192, 0.9)'}} />
                  </div>
                  {/* Ajoutez ici d'autres éléments à afficher à droite de chaque élément de la liste si nécessaire */}
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default CardNavBar;
