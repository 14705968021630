import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import RestaurantProfile from './RestaurantProfil';
import './MyHome.css'
const MyHome = ({ logo = '', restaurant, reviews,isMyHomeClicked}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div
        style={{
          marginTop: '2%',
          position: 'absolute',
          top: '0%',
          left: '85%',
          color: 'rgba(192, 192, 192, 0.5)',
          fontSize: '1.3rem',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          border: `2px solid `,
          borderRadius: '50%',
          padding: '5px',
          cursor: 'pointer',
          transition: 'border-color 1s linear',
        }}
        onClick={handleOpenModal}
      >
        {logo && logo.length > 0 ? (
          <div className={isMyHomeClicked ? 'shake-animation' : ''} style={{ width: '35px', height: '35px', borderRadius: '50%', overflow: 'hidden' }}>
            <img src={logo} alt="Logo" style={{ width: '100%', height: '100%' }} />
          </div>
        ) : (
          <FontAwesomeIcon icon={faHouse} />
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '5px',
            borderRadius: '10px',
            backgroundColor: 'rgba(192, 192, 192, 0.3)',
          }}
        >
          <RestaurantProfile
            logo={logo}
            restaurantName={restaurant.name}
            stars={restaurant.rating}
            reviews={reviews}
            photos={restaurant.images}
            socialLinks={restaurant.socialLinks}
            description={restaurant.description}
          />
        </div>
      </Modal>
    </>
  );
};

export default MyHome;
