import React, { useEffect, useState, useRef } from 'react';

const NavBar = ({ navItems, onSelectSection, data, firstItem }) => {
  const [selectedItem, setSelectedItem] = useState(firstItem);
  const navBarRef = useRef(null);

  const handleSelectSection = (item) => {
    setSelectedItem(item);
    onSelectSection(item);
  };

  useEffect(() => {
    // Scroll to the start of the nav bar on mount
    if (navBarRef.current) {
      navBarRef.current.scrollLeft = 0;
    }
  }, []);

  return (
    <div
      ref={navBarRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingBottom: '10px',
        marginTop: '5%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'none', // Firefox
        WebkitOverflowScrolling: 'touch', // Enable smooth scrolling on iOS
      }}
    >
      {navItems.map((item, index) => (
        <div
          key={index}
          style={{
            marginRight: '10%',
            fontWeight: 500,
            color: 'black',
            cursor: 'pointer',
            display: 'inline-block',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
          onClick={() => handleSelectSection(item.type)}
        >
          <div
            style={{
              marginLeft: '10%',
              width: '50px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              backgroundColor: selectedItem === item.type ? 'rgba(192, 192, 192, 0.3)' : 'rgba(50, 50, 50, 0.3)',
            }}
          >
            <img
              src={item.image}
              alt={item.type}
              style={{
                width: '30px',
                height: '30px',
              }}
            />
          </div>
          <div style={{ color: selectedItem === item.type ? 'rgba(192, 192, 192, 1)' : 'rgba(192, 192, 192, 0.3)' }}>
            {item.type}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NavBar;
