import React, { useEffect, useState } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { connect } from 'react-redux';
const StyledButton = ({ onClick, cartItems }) => {
  console.log('cartItems......', cartItems.length)
  useEffect(()=>{

  }, [cartItems])
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '50px', // Adjust the distance from the bottom as needed
        right: '20px', // Adjust the distance from the right as needed
        cursor: 'pointer', // Add a cursor to indicate it's clickable
        borderRadius: '50%', // Adjust the border-radius for a circular shape
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Add a subtle box shadow
        backgroundColor: 'rgba(192, 192, 192, 1)', // Set the background color
        padding: '10px', // Add padding to make it clickable
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid rgba(255, 255, 255, 0.8)', // Bordure colorée
      }}
      onClick={onClick} // Add the onClick event to handle the click
    >
      <ShoppingCartIcon sx={{ fontSize: 30 }} />
      {cartItems?.length > 0 ? ( // Render the cartItems length conditionally
        <div
          style={{
            position: 'absolute',
            top: '-5px',
            right: '2px',
            color: 'black', // Set the text color to black
            padding: '5px', // Add padding
            fontSize: '14px', // Adjust the font size
            minWidth: '20px', // Set a minimum width
            textAlign: 'center', // Center the text horizontally
          }}
        >
          {cartItems?.length}
        </div>
      ):null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state, // Utilisez state directement si vos éléments de panier sont stockés au niveau racine de l'état Redux
});

export default connect(mapStateToProps)(StyledButton);
