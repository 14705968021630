import React, { useState, useEffect } from 'react';
import NavBar from "./Navbar";
import Item from './item'; // Assuming your component is named 'Item', adjust accordingly
import Cart from './Cart';
import ScrollToTop from './ScrollToTop';
import Drawer from '@mui/material/Drawer';
import Order from './Order';
import client from '../api/client';
import SplashScreen from './SplashScreen';
import { useParams } from 'react-router-dom';
import socket from '../api/socket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHouse } from '@fortawesome/free-solid-svg-icons';//QR Code aura cette allure : http://localhost:3000/restaurant/65db45f64b7bc4c560d7f981/3
import SearchItem from './SearchItem';
import './HomePage.css'
import CardNavBar from './CardNavBar';
import AllItems from './AllItems';
import MyHome from './MyHome';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const HomePage = () => {
  const {id, numberTable} = useParams();
  console.log("id, table",id, numberTable)
  const [data, setData] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState('');
  const [showSplash, setShowSplash] = useState(true);
  const [numberTables, setNumberTables] =useState(0)
  const [restaurantName, setRestaurantName] =useState('')
  const [restaurant, setRestaurant] =useState('')
  const [name, setName] = useState('')
  const [logo, setLogo] = useState('')
  const [token, setToken] = useState('')
  const[oldClient, setOldClient] = useState(false)
  const [images, setImages] = useState([])
  const [currencies, setCurrencies] = useState({})
  const [searchTerm, setSearchTerm] = useState("");
  const [dateSearch, setDataSearch] = useState([])
  const [updatedNavItems, setUpdatedNavItems] = useState([]); // Déplacer la définition ici
  const [topProducts, setTopProducts] = useState([])
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [firstItemNavBar, setFirstItemNavBar] = useState('')
  const [reviews, setReviews] = useState('')
  const [email, setEmail] = useState('')
  const [waitingTime, setWaitingTime] = useState(0)
  //const [serverTime, setServerTime] = useState(0)
  const [isMyHomeClicked, setIsMyHomeClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const searchTerm = event.target.value.toLowerCase(); // Convertir le terme de recherche en minuscules
    const searchData = []; // Tableau pour stocker les résultats de la recherche
  
    // Filtrer les éléments de data
    data.forEach((typeItem) => {
      const filteredData = typeItem.data.filter((category) => {
        // Vérifier si le nom de la catégorie correspond au terme de recherche
        if (category.nom.toLowerCase().includes(searchTerm)) {
          return true;
        }
        // Si le terme de recherche ne correspond pas, vérifier les produits dans la catégorie
        const filteredProducts = category.dataProduct.filter((product) =>
          product.name.toLowerCase().includes(searchTerm)
        );
        // Si des produits correspondent, ajouter la catégorie avec les produits filtrés
        if (filteredProducts.length > 0) {
          searchData.push({ ...category, dataProduct: filteredProducts });
          return false; // Ne pas ajouter la catégorie à nouveau
        }
        return false; // Ne pas conserver la catégorie si aucun produit ne correspond
      });
  
      // Ajouter les catégories filtrées (si des catégories correspondent)
      if (filteredData.length > 0) {
        searchData.push({ ...typeItem, data: filteredData });
      }
    });
  
    // Mettre à jour l'état dataSearch avec les résultats de la recherche
    setDataSearch(searchData);
  };
  
  const handleSplashComplete = () => {
    setShowSplash(false);
  };
  const handleName = (name)=>{
    console.log("handle name ", name)
    setName(name)
  }
  const handleOldClient =()=>{
    setOldClient(true)
  }
  const handleMyHomeClick = ()=>{
    setIsMyHomeClicked(false)
  }
  const validateOrder = () => {
    setOpenModal(true);
    setTimeout(()=>{
      setIsMyHomeClicked(true);
    },5000)
  };
  const fetchRestaurantInfo = async () => {
    const oldToken = JSON.parse(localStorage.getItem("token"))
    console.log('oldToken....', oldToken)
    try {
      await client.get(`get-restaurantData?restaurantId=${id}&numberTable=${numberTable}`,{
        headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            authorization : oldToken,
          }
        }).then((res) => {
        if (res.data.success) {
          localStorage.setItem("token", JSON.stringify(res.data.token))
          setToken(res.data.token)
          if(res.data.name&&res.data.name?.length > 0) setName(res.data.name)
          if(res.data.email&&res.data.email?.length > 0){    localStorage.setItem('email',res.data.email)
        }
          if(res.data.oldClient) setOldClient(true)
          const newData = res.data.data
         const NavItems = [...newData.map((item) => ({ type: item.type, image: item.image }))];
         console.log("data NavItems...", res.data)
         setUpdatedNavItems(NavItems)
         setFirstItemNavBar(NavItems[0].type)
          setData(newData);
          setTopProducts(res.data.topProducts)
          setImages(res.data?.restaurant?.images || [])
          setLogo(res.data?.restaurant?.logo)
          setSelectedSection(newData[0]?.type)
          setRestaurantName(res.data?.restaurant?.name)
          setNumberTables(res.data.restaurant?.tables?.length)
          setCurrencies(res.data.restaurant?.currencies)
          setIsValid(true);
          setRestaurant(res.data.restaurant)
          setReviews(res.data.reviews)
          setWaitingTime(res.data.watingTime)
          const managerRoom = `manager-${res.data.restaurant?._id}`;
          socket.emit('joinRoom', managerRoom);
                  
                      // Clean up the socket listener when the component unmounts
                      return () => {
                        socket.off('joinRoom');
                      };
        } else {
          setError(res.data.error);
        }
      })
    } catch (error) {
      console.error('Error fetching restaurant info', error);
    }
  };

  useEffect(() => {
    const oldToken = localStorage.getItem("token");
    console.log("check token", oldToken);
  
    if (oldToken !== null && oldToken !== undefined) {
      const oldName = localStorage.getItem("name");
      // setName(oldName)
      console.log("déjà un token ", oldToken);
      setToken(oldToken);
    }
  
    const cleanupSocket = fetchRestaurantInfo();
    return cleanupSocket;
  }, [id, numberTable]);
  useEffect(() => {
    console.log("dans home page", data);
  }, [data, selectedSection, restaurantName, numberTables]);

  const handleSectionClick = (section) => {
    setFirstItemNavBar(section)
    setSelectedSection(section);
  };

  const handleCartClick = () => {
    setIsCartOpen(true);
  };

  const handleCloseCart = () => {
    setIsCartOpen(false);
  };
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(true);
    setOpenImageDialog(true)
    setTimeout(()=>{
      setExpanded(false);
    }, 200)
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };

  return (
<div className="HomePage">
      {isValid ? (
        showSplash ? (
          <div className="SplashScreenWrapper"> {/* Div d'enveloppe pour SplashScreen */}
            <SplashScreen
              name={restaurantName}
              onSplashComplete={handleSplashComplete}
              handleName={handleName}
              clientName={name}
              images={images}
              logo={logo}
            />
          </div>
        ) : (
          <>
          <div style={{ position: 'fixed',width:'98vw', top: 0, left: 0, right: 0, zIndex:1, backgroundColor:'#161b22',}}>
            <div style={{ fontWeight: 'bold', fontSize: 25, color:'rgba(192, 192, 192, 0.5)', marginLeft:'5%', marginTop:'5%'}}>Salut {name}</div>
            <div onClick={handleMyHomeClick}>
              <MyHome logo={logo} restaurant={restaurant} reviews={reviews} isMyHomeClicked={isMyHomeClicked}/>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: '10px', }}>
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: '10px', color: 'rgba(192, 192, 192, 0.5)' }} />
              <input
                type="text"
                placeholder="Que désirez-vous ?..."
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  width: '80%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid rgba(192, 192, 192, 0.3)',
                  backgroundColor: 'rgba(192, 192, 192, 0.3)',
                  marginRight:'2%'
                }}
              />
              <div className={`circle ${expanded ? 'expanded' : ''}`} onClick={handleClick}>
                <div className="point"></div>
                <div className="point"></div>
                <div className="point"></div>
              </div>


            </div>
            <div>
              <NavBar
              onSelectSection={handleSectionClick}
              onCartClick={handleCartClick}
              navItems={updatedNavItems || []}
              data={data}
              firstItem={firstItemNavBar}
            />
            </div>
          </div>
          
          <div>
              {topProducts.length>0?<AllItems data={topProducts} currencies={currencies}/>:<div style={{marginTop:'60%',}}></div>}
          </div>
            
            <div style={{width:'94vw'}}>
              {searchTerm.length>0 ?(
              dateSearch?.map((item) => (
                <React.Fragment key={item.type}>
                  <SearchItem data={item} currencies={currencies} />
                </React.Fragment>
              ))
              ):(
              data?.map((item) => (
                <React.Fragment key={item.type}>
                  {selectedSection === item.type && <Item data={item} currencies={currencies} />}
                </React.Fragment>
              ))
              )}
            </div>
            
            <div>
              <Cart onClick={handleCartClick} />
            </div>
            <ScrollToTop />
  
            <Drawer
              anchor="bottom"
              open={isCartOpen}
              onClose={handleCloseCart}
            >
              <div
                style={{
                  width: '80%',
                  padding: '10%',
                  maxHeight: '5O%',
                  overflowY: 'auto',
                  backgroundColor:'#161b22'
                }}
              >
                    <div
            style={{
              position: 'absolute',
              top: '10px', // Distance par rapport au haut
              left: '10px', // Distance par rapport à la gauche
              cursor: 'pointer', // Changer le curseur pour pointer pour indiquer que c'est cliquable
              color: 'rgba(192, 192, 192, 0.8)' // Couleur de l'icône
            }}
            onClick={handleCloseCart}
          >
            <CloseIcon />
          </div>
                {/* Contenu de votre Drawer, ici le composant Order */}
                <Order
                  numberOfTables={numberTables}
                  defaultNumberTable={numberTable}
                  token={token}
                  name={name}
                  oldEmail={email}
                  validateOrder={validateOrder}
                  id_restaurant={id}
                  oldClient={oldClient}
                  handleOldClient={handleOldClient}
                  currencies={currencies}
                />
              </div>
            </Drawer>
            <div style={{ position: 'fixed', top: 0, left: 0 }}>
            <CardNavBar 
              onSelectSection={handleSectionClick}
              onCartClick={handleCartClick}
              navItems={updatedNavItems || []}
              //setItem={setFirstItemNavBar}
              data={data}
              open={openImageDialog} 
              onClose={() => setOpenImageDialog(false)} 
            />
          </div>
          <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Votre commande a été passée avec succès !
                  Et sera servie dans {waitingTime} minutes.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  En attendant, vous pouvez cliquer sur notre logo en haut à droite pour en savoir plus sur notre restaurant et donner votre avis.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, fontWeight:'bold' }}>
                  Merci de votre patience et de votre fidélité !
                </Typography>
                <CloseIcon
                  style={{ alignSelf: 'flex-end', cursor: 'pointer', marginTop: '20px' }}
                  onClick={handleCloseModal}
                />
              </Box>
            </Modal>
          </>
        )
      ) : null}
    </div>
  );
  
};

export default HomePage;
