import React, { useState } from 'react';
import { connect } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart, clearCart } from './actions'; // Define these actions
import ArticleCard from './ArticleCard';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { motion, useMotionValue, useTransform } from "framer-motion"
import { CircularProgress } from './CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import client from '../api/client';


const Order = ({ cartItems, dispatch, numberOfTables, defaultNumberTable, name, validateOrder, id_restaurant, oldClient, handleOldClient,currencies}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState(defaultNumberTable);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleConfirm = async () => {
    handleSendOrder();
    handleClose();
  };
  // La fonction handleChange sera appelée lorsque la valeur sélectionnée change
  const handleChange = (event) => {
    setSelectedTable(event.target.value); // Mettre à jour la valeur sélectionnée
  };
  const [email, setEmail] = useState(localStorage.getItem('email') ? localStorage.getItem('email'):'');
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isError, setIsError] = useState(false)
  const [onPlace, setOnPlace] = useState(true)
  
  let progress = useMotionValue(90)
  const isValidEmail = (email) => {
    if(email.length <= 0) return true
    console.log("oldClient, email...",email)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleIncrement = (article) => {
    dispatch(incrementQuantity(article));
  };

  const handleDecrement = (article) => {
    dispatch(decrementQuantity(article));
  };

  const handleDelete = (article) => {
    dispatch(removeFromCart(article));
  };

  const handleValidateOrder = async() => {
    setIsModalOpen(true);
  };
  const OnPLaceTrue = ()=>{
    if(oldClient){
      setEmail(localStorage.getItem('email') ? localStorage.getItem('email'):'')
      setOnPlace(true)
      //handleSendOrder()
      handleClickOpen()
    }
    else{
    setOnPlace(true)
    handleValidateOrder()
  }
  }
  const OnPLaceFalse = ()=>{
    if(oldClient){
      setEmail(localStorage.getItem('email') ? localStorage.getItem('email'):'')
      setOnPlace(false)
      //handleSendOrder()
      handleClickOpen()
    }
    else{
      setOnPlace(false)
      handleValidateOrder()
    }
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsErrorVisible(false);
  };
  const handleSetEmail = (e)=>{
    console.log("email....", email)
    setEmail(e.target.value)
    localStorage.setItem('email',e.target.value)
  }

  const handleSendOrder = async () => {
    
    if (isValidEmail(email)) {
      const clientLocalTime = new Date();
      // Obtenir le fuseau horaire actuel du client
      const clientTimeZoneOffset = clientLocalTime.getTimezoneOffset(); // Obtenez le décalage par rapport à l'heure UTC en minutes
      const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Obtenez le nom du fuseau horaire
      console.log('send orders....', clientTimeZoneOffset, clientTimeZone)
      const token = JSON.parse(localStorage.getItem("token"))
      if(selectedTable==0||selectedTable=='0'){
        handleOldClient()
              setIsModalOpen(false);
              setIsSuccessModalOpen(true);
          // Fermer la modal de succès après 2 secondes
          setTimeout(() => {
            setIsSuccessModalOpen(false);
          }, 2000);

          // Nettoyer le panier après 2 secondes
          setTimeout(() => {
            dispatch(clearCart()); // Ajoutez l'action appropriée pour vider le panier
            validateOrder()
          }, 1000);
      }else{
          try {
          await client.post('/createClientAndOrder',{cartItems, nom_client:name,email,id_restaurant, token, tableNumber:selectedTable, price:calculateTotalPrice(), onPlace, clientLocalTime, clientTimeZoneOffset, clientTimeZone},{
          headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
              authorization : `JWT ${token}`,
            }
          }).then((res)=>{
            if(res.data.success){
                handleOldClient()
                setIsModalOpen(false);
                setIsSuccessModalOpen(true);
            // Fermer la modal de succès après 2 secondes
            setTimeout(() => {
              setIsSuccessModalOpen(false);
            }, 2000);

            // Nettoyer le panier après 2 secondes
            setTimeout(() => {
              dispatch(clearCart()); // Ajoutez l'action appropriée pour vider le panier
              validateOrder()
            }, 1000);
            }else{
              setIsError(true)
              setTimeout(() => {
                setIsError(true)
              }, 3000);
            }
          })
        } catch (error) {
          console.log("error.....", error)
        }
      }
      
      //console.log(`Commande validée avec l'e-mail : ${email}`);
      
      

    } else {
      console.error('Adresse e-mail non valide. Veuillez entrer une adresse e-mail correcte.');
      setIsErrorVisible(true);
      // Masquer le message d'erreur après 5 secondes
      setTimeout(() => {
        handleValidateOrder()
        setIsErrorVisible(false);
      }, 3000);
    }
  };
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      console.log('item in cart', item.price, totalPrice);
      totalPrice += parseFloat(item.price);
    });
    return totalPrice.toFixed(2);
  };
  

  const tableNumbers = defaultNumberTable == 0 || defaultNumberTable == '0' ?[0]:Array.from({ length: numberOfTables }, (_, index) => index + 1);
//{cartItems?.length>1?<div style={{fontWeight:700}}>Prix Total : {calculateTotalPrice()}</div>:null}

  return (
    <div
    >
    <div style={{ maxHeight: '10%', overflowY: 'auto' }}>
      {cartItems.map((item, index) => (
              <ArticleCard
                key={index}
                article={item}
                onIncrement={() => handleIncrement(item)}
                onDecrement={() => handleDecrement(item)}
                onDelete={() => handleDelete(item)}
                currencies={currencies}
              />
            ))}
    </div>
      
      <Box sx={{ maxWidth: '20%', paddingTop: '10%', paddingBottom: '10%' }}>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="table-select"     sx={{ color: 'rgba(192, 192, 192, 0.5)' }} // Ajout de la couleur pour le label
          >
            Table
          </InputLabel>
          <NativeSelect
      value={selectedTable} // Utiliser la valeur sélectionnée comme valeur du composant NativeSelect
      onChange={handleChange} // Définir la fonction de gestionnaire de changement
      inputProps={{
        name: 'table',
        id: 'table-select',
      }}
      sx={{ // Ajout des styles pour le NativeSelect
      color: 'rgba(192, 192, 192, 0.5)', // Couleur du texte sélectionné
      '.MuiSvgIcon-root': { // Styliser l'icône de la flèche
        color: 'rgba(192, 192, 192, 0.5)',
      },
    }}
      >
      {tableNumbers.map((tableNumber) => (
        <option  key={tableNumber} value={tableNumber}
                style={{ color: 'rgba(192, 192, 192, 0.5)' }} // Couleur du texte des options
        >
          {tableNumber}
        </option>
      ))}
    </NativeSelect>
        </FormControl>
      </Box>

      {cartItems.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            sx={{ marginRight: '10px', backgroundColor: 'rgba(192, 192, 192, 0.5)', ':hover': { backgroundColor: 'white', color:'black', borderColor:'black' } }}
            variant="contained"
            onClick={OnPLaceTrue}
          >
            Sur Place
          </Button>
          <Button
            sx={{ color: 'rgba(192, 192, 192, 0.5)', borderColor: 'rgba(192, 192, 192, 0.5)', ':hover': { backgroundColor: 'black', color: 'white' } }}
            variant="outlined"
            onClick={OnPLaceFalse}
          >
            Emporter
          </Button>
        </div>

        
      )}

      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            width: 300,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <h4>Entrez votre E-mail pour recevoir la facture</h4>

          <TextField
            label="Entrez votre e-mail"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleSetEmail}
          />
          <Button
            sx={{ marginTop: 2, marginLeft: "12%", backgroundColor: 'black', ':hover': { backgroundColor: 'black' } }}
            variant="contained"
            onClick={handleSendOrder}
          >
            Envoyer ma commande
          </Button>
        </Box>
      </Modal>

      {/* Modal de succès */}
      <Modal open={isSuccessModalOpen}>
  <div style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center', // Ajout de cette ligne pour centrer le contenu
  }}>
    <motion.div
      initial={{ x: 0 }}
      animate={{ x: 100 }}
      style={{ x: progress, width: '50%', margin: '0 auto' }} // Ajustez la taille de l'animation
      transition={{ duration: 1 }}
    />
    <CircularProgress progress={progress} />
  </div>
</Modal>


      {/* Affichez le message d'erreur */}
      {isErrorVisible && (
        <div style={{ position: 'fixed', top: '20px', right: '20px', backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '5px' }}>
          Adresse e-mail non valide. Veuillez entrer une adresse e-mail correcte.
        </div>
      )}
      {isError && (
        <div style={{ position: 'fixed', top: '20px', right: '20px', backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '5px' }}>
        Un problème est survenu, veuillez vous rendre au comptoi
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation de commande"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir envoyer cette commande ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} style={{ backgroundColor: 'black', color: 'white' }}>
            Annuler
          </Button>
          <Button onClick={handleConfirm} style={{ backgroundColor: 'black', color: 'white' }} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state,
});

export default connect(mapStateToProps)(Order);
