import React, { useEffect, useState } from 'react';
import './SplashScreen.css';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';

const SplashScreen = ({ onSplashComplete, name, handleName, clientName, images, logo }) => {
  const title = `Bienvenue chez ${name}`.split(' ');
  const [animationComplete, setAnimationComplete] = useState(false);
  const [newName, setNewName] = useState('');
  const [error, setError] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animationActive, setAnimationActive] = useState(false);

  const startAnimation = () => {
    setAnimationActive(true);
  };

  // Function to cycle through images
  const cycleImages = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    startAnimation()
    const imageCycleInterval = setInterval(() => {
      cycleImages();
    }, 5000); // Change image every 3 seconds
    return () => clearInterval(imageCycleInterval);
  }, [images,cycleImages ]);
  

  useEffect(() => {
    if (clientName && clientName?.length > 0) {
      onSplashComplete();
    } else {
      const animationTimeout = setTimeout(() => {
        setAnimationComplete(true);
      }, 3000);

      return () => clearTimeout(animationTimeout);
    }
  }, [clientName, onSplashComplete]);

  const handleNameChange = (event) => {
    const anName = event.target.value;
    console.log("name ", anName);
    setNewName(anName);
  };

  const goToOrder = () => {
    if (newName.length >= 3) {
      onSplashComplete();
      handleName(newName);
    } else if(newName.length <= 3){
      setError('Votre prénom doit être de minimum 3 syllabes');
      setTimeout(() => {
        setError('');
      }, 3000);
    }else {
      setError('Veuillez bien entrez votre nom');
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  };
  return (
  <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `
      linear-gradient(to top, rgba(0, 0, 0, 0.7) 50%, rgba(255, 255, 255, 0) 50%), 
      linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 50%, rgba(255, 255, 255, 0) 50%), 
      url(${images[currentImageIndex]})
    `,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100vw', // Utilisation de 100vw pour occuper toute la largeur de la vue
    height: '100vh', // Full height of the viewport
    position: 'relative',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    backgroundColor: 'rgba(255,255,255,0.5)', // This is for the glass effect
  }}>    
    {!animationComplete ? ( logo && logo.length > 0 ? (
      <div className="logo-container">
        <motion.img
          className={`logo ${animationActive ? 'active' : ''}`}
          src={logo}
          alt="Logo"
          initial={{ scale: 0.1, opacity: 0 }} // Taille initiale et opacité
          animate={{ scale: 1, opacity: 1 }} // Taille et opacité à animer
          transition={{ duration: 1 }} // Durée de l'animation
        />
      </div>
    ) :
      <div className={`splash-screen ${animationComplete ? 'hide' : ''}`}>
        <div className="splash-content">
          {title.map((el, i) => (
            <motion.h2
              key={i}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: i / 10,
              }}
            >
              {el}{' '}
            </motion.h2>
          ))}
        </div>
      </div>
    ) : (
      <>
        {error&&error.length> 0 ?<div className="blurry-background-text" style={{ color: "gray", position: "absolute", top: "30%", left: '50%', transform: 'translate(-50%, -50%)', }}>
          {error}
        </div>:null}
        <div className="centered-container">
          <div className="main">
            <TextField
              required
              id="standard-basic"
              label="Votre nom"
              variant="standard"
              onChange={handleNameChange}
              sx={{ width: '200px', paddingBottom: '10%' }}
              inputProps={{ style: { fontSize: '200%', color: 'gray' } }}
              InputLabelProps={{ style: { color: 'white' } }}
              InputProps={{ style: { color: 'white', borderBottom: '1px solid white' } }}
            />
          </div>
          <button className="cssbuttons-io-button" onClick={goToOrder}>
            Je commande !! 
            <div className="icon">
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
              </svg>
            </div>
          </button>
        </div>
      </>
    )}
  </div>
);

};


export default SplashScreen;
